<template>
  <v-app id="app" :class="{mobile: $vuetify.breakpoint.mobile}">

    <main-header/>

    <v-layout column>
      <router-view/>
    </v-layout>

    <MainFooter v-if="!$route.meta.hideFooter"/>

    <cookies-policy-message/>
    <albi/>
    <wa-chat classs="main-wa-chatbot" fab :width="btnSize" :height="btnSize" dark :icon-size="$vuetify.breakpoint.mdAndDown ? 'xl' : '2xl'" />
  </v-app>
</template>

<script type="text/javascript">
import MainHeader from './common/components/main-header'
import MainFooter from './common/components/main-footer'
import CookiesPolicyMessage from './common/components/cookies-policy-message'
import Albi from './common/components/albi'
import WaChat from './common/components/wa-chatbot'
import { mapActions } from 'vuex'
import { postRecaptchaToken } from '@/common/services/api-service'

export default {

  name: 'Rbns',

  components: {
    MainHeader,
    MainFooter,
    CookiesPolicyMessage,
    WaChat,
    Albi
  },

  // data () {
  //   return {
  //   }
  // },

  computed: {
    btnSize () {
      return this.$vuetify.breakpoint.lgAndUp ? '54px' : '50px'
    }
  },

  methods: {
    ...mapActions(['setBot', 'setLocale'])
  },

  async created () {
    this.setLocale(this.$config.locale.lang)
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('page_load')
    const res = await postRecaptchaToken(token)
    this.setBot(res.data.score < 0.5)
  }
}

</script>

<style lang="scss">
  .main-wa-chatbot {
    position: fixed;
    bottom: 180px;
    right: 10px;
    z-index: 1;
    border: 3px solid var(--v-bright-base)!important;
  }
</style>
